/**
 * Classe utilitária do certificado digital.
 * 
 * @type {Assinatura}
 */
class CertificadoUtil {

    /**
     * Método construtor da classe.
     * 
     * @return {Assinatura}
     */
    constructor() {
    }

    /**
     * Método responsável por popular o combo com os certificados.
     * 
     * @param {HTMLElement} listaCertificadosElement
     * @param {object[]} listCertificadosDisponiveis
     * @return {void}
     */
    static async populateCertificados(listaCertificadosElement, listCertificadosDisponiveis) {
        let optionEmpty = null;
        
        if (listCertificadosDisponiveis != undefined && listCertificadosDisponiveis.size > 0) {
            optionEmpty = document.createElement("option");
            optionEmpty.value = '';
            optionEmpty.text = 'Selecione um certificado';
        } else {
            optionEmpty = document.createElement("option");
            optionEmpty.value = '';
            optionEmpty.text = 'Não há certificados disponíveis';
        }
        
        listaCertificadosElement.add(optionEmpty);

        if (listCertificadosDisponiveis != undefined) {
          for (var [key, value] of listCertificadosDisponiveis) {
            let option = document.createElement("option");
            option.value = key;
            option.text = value.alias;

            listaCertificadosElement.add(option);
          }
        }
    }
}
