/**
 * Classe responsável por gerenciar a comunicação com o assinador.
 *
 * @type {AssinadorAdapter}
 */
class AssinadorAdapter {

    /**
     * Método construtor da classe.
     *
     * @return {AssinadorForm}
     */
    constructor() {
        this.comboCertificados = document.querySelector('#combo_certificados');
    }

    /**
     * Método responsável por realizar a assinatura de PDF.
     *
     * @param {string} body
     * @param {string} id
     * @return {object}
     */
    async signPdf(body, id) {
        let headers = new Headers();
        headers.append('X-Requested-With', 'XMLHttpRequest');
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        let result = await fetch(`/digitalsignature/signpdf/id/${id}`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers,
            credentials: 'same-origin'
        });

        if (result.status >= 400) throw new Error('Houve um erro ao realizar a assinatura, o registro ficará como pendente.');

        let data = null;
        if (result.headers.get('content-type').includes('application/json')) {
            data = await result.json();
        } else {
            data = await result.text();

            throw new Error(data);
        }

        if (result.status !== 200) {
            throw new Error(data.title);
        }

        return data;
    }

    /**
     * Método responsável por verificar o status da assinatura de PDF.
     *
     * @param {string} id
     * @return {object}
     */
    async statusPdf(id) {
        let headers = new Headers();
        headers.append('X-Requested-With', 'XMLHttpRequest');
        headers.append('Accept', 'application/json');

        let result = await fetch(`/digitalsignature/status/id/${id}`, {
            method: 'GET',
            headers,
            credentials: 'same-origin'
        });

        if (result.status >= 400) throw new Error('Houve um erro ao verificar o status da assinatura, tente novamente.');

        let data = null;

        if (result.headers.get('content-type').includes('application/json')) {
            data = await result.json();
        } else {
            data = await result.text();

            throw new Error(data);
        }

        return data;
    }

    /**
     * Método responsável por realizar a verificação de PDF.
     *
     * @param {string} id
     * @param {boolean} jsonReturn
     * @return {object}
     */
    async validPdf(id, jsonReturn = true) {
        let headers = new Headers();
        headers.append('X-Requested-With', 'XMLHttpRequest');
        headers.append('Accept', jsonReturn ? 'application/json' : 'application/text');

        let result = await fetch(`/digitalsignature/verify/id/${id}`, {
            method: 'GET',
            headers,
            credentials: 'same-origin'
        });

        if (result.status >= 400) throw new Error('Houve um erro ao validar a assinatura, tente novamente.');

        let data = null;

        if (result.headers.get('content-type').includes('application/json')) {
            data = await result.json();
        } else {
            data = await result.text();
        }

        return data;
    }

    /**
     * Método responsável por tratar os erros
     * @param {Error} error
     * @returns {void}
     */
    async _trataErro(error) {
        switch (true) {
            case new RegExp(/^Acesso\snão\sautorizado.+/).test(error.message):
                throw new Error('Acesso não autorizado');
                break;
            default:
                throw error;
        }
    }
}