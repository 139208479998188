/* global BryAdapterFactory, AssinadorAdapter, AssinadorAdapterFactory */

/**
 * Classe responsável por realizar a assinatura.
 * 
 * @type {Assinatura}
 */
class Assinatura {

    /**
     * Método construtor da classe.
     * 
     * @return {Assinatura}
     */
    constructor() {
        this.assinadorAdapter = AssinadorAdapterFactory.getInstance();
    }
}