/* global CertificadoUtil, AssinadorAdapterUtil */

let _instance = null;

/**
 * Classe responsável por gerenciar a instância do AssinadorAdapter
 * 
 * @type {AssinadorAdapterFactory}
 */
class AssinadorAdapterFactory {
    /**
     * Método construtor da classe.
     * 
     * @return {AssinadorAdapterFactory}
     */
    constructor() {
        throw new Error('Classe estática. Não deve ser instanciada.');
    }

    /**
     * Método responsável por retornar a instância do AssinadorAdapter
     * 
     * @return {AssinadorAdapterFactory}
     */
    static getInstance() {
        if (!(_instance instanceof AssinadorAdapter)) {
            _instance = new AssinadorAdapter();
        }

        return _instance;
    }
}
