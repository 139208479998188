/**
 * Classe responsável por buscar os certificados do usuário.
 *
 * @type {Certificate}
 */

class Certificate {

    /**
     * Método responsável por buscar os certificados do usuário.
     *
     * @param {string} cpf
     * @return {undefined}
     */
    static async getCertificatesByCpf(cpf) {
        const headers = new Headers();
        headers.append('X-Requested-With', 'XMLHttpRequest');
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        const result = await fetch(`/digitalsignaturegetcertificates/getandpersist/cpf/${cpf}`, {
            method: 'GET',
            headers,
            credentials: 'same-origin'
        });

        return await result.json();
    }
}
