class Utils {

    /**
     * Método responsável por formatar a mensagem com os dados do certificado.
     *
     * @param dataValidSignature
     * @param dataStatusSignature
     */
    static factoryMessageCertificates(dataValidSignature = null, dataStatusSignature = null) {
        let message = "";

        return 'Assinado com sucesso!';

        message += "Resultado da assinatura/verificação: \n";

        if (dataStatusSignature.status_id === 2 && dataStatusSignature) {
            message += "\n";
            message += "Status do documento:: " + dataValidSignature.situacao + "\n";
            message += "Arquivo integro: " + (dataValidSignature.hash ? 'SIM' : 'NÃO') + "\n";
            message += "Assinatura válida: " + (dataValidSignature.validSignature ? 'SIM' : 'NÃO') + "\n";

            if (dataValidSignature.hasErrorCertificateSelfSigned)
                message += "Erro: O certificado usado para assinatura não é ICP-Brasil."+ "\n";

            message += "Certificado(s): " + "\n\n";

            let count = 1;

            for (let signature of dataValidSignature.signatures) {
                message += "Assinatura: " + count++ + "\n";
                message += "    Expirado: " + (signature.certificates[0].expired ? 'SIM' : 'NÃO') + "\n";
                message += "    Revogado: " + (signature.certificates[0].revoked ? 'SIM' : 'NÃO') + "\n";
                message += "    Nome comum: " + signature.certificates[0].subjectName + "\n";
                message += "    Emissor: " + signature.certificates[0].issuerName + "\n\n";
            }
        }

        if (dataStatusSignature.status_id === 7)
            message += "Houve um problema na assinatura/verificação. Tente novamente mais tarde.";

        if (dataStatusSignature.status_id === 7)
            message += "Houve um problema na assinatura/verificação. Tente novamente mais tarde.";

        return message;
    }


    /**
     * Método responsável por formatar a mensagem de erro.
     *
     * @param errors
     */
    static factoryMessageErrorsSign(resultSign = null) {
        let message = "Houve um problema na assinatura/verificação\n\n";

        return message;

        if (resultSign.resultSign.validTSA === false)
            message += "- Houve um erro ao validar o servidor de carimbo de tempo: \n\n";

        if (resultSign.resultSign.validlCR === false)
            message += "- Houve um erro ao validar a LCR: \n\n";

        if (resultSign.resultSign.errorsCertificate.length) {
            message += "\nErros certificado digital: \n\n";

            message += resultSign.resultSign.errorsCertificate.join('\n');
        }

        message += "\n\nTente novamente mais tarde. O registro permanecerá pendende para assinatura.";

        return message;
    }

    /**
     * Método responsável por abrir um modal com o conteúdo informado no parâmetro data.
     *
     * @param data
     * @param title
     */
    static openBlockUI(data, title) {
        if (data) {
            // @ts-ignore
            $.fn.center = function () {
                this.css("position", "absolute");
                this.css("top", ($(window).height() - this.height()) / 7 + $(window).scrollTop() + "px");
                this.css("left", ($(window).width() - this.width()) / 7 + $(window).scrollLeft() + "px");
                return this;
            }

            //@ts-ignore
            $.blockUI({
                theme: true,
                draggable: true,
                bindEvents: false,
                title: title + '<a href=\"javascript:;\"  class=\"btn-i-nao right fechar_msg\"></a>',
                message: data,
                themedCSS: {
                    position: 'absolute',
                    width: '80%',
                    height: 'auto',
                    left: '10%',
                    top: '10%'
                }
            });

            $('.fechar_msg').click(() => {
                //@ts-ignore
                $.unblockUI();
            });

            // @ts-ignore
            $('.blockUI.blockMsg').center();
        }
    };

    /**
     * Método responsável por abrir o modal de aguarde ao iniciar um processo.
     */
    static startWait() {
        // @ts-ignore
        $.blockUI({message: '<h2>Aguarde...</h2>'});
    }

    /**
     * Método responsável por fechar o modal de aguarde ao concluir um processo.
     */
    static stopWait() {
        // @ts-ignore
        $.unblockUI();
    }

    /**
     * Método responsável por criar um elemento loading.
     */
    static createLoadingElement() {
        const loaddingElement = document.createElement('img');
        loaddingElement.src = '/images/spinner.gif';

        return loaddingElement;
    }

    /**
     * Método responsável por retornar um objeto Headers configurado.
     */
    static getHeadersJson() {
        const headers = new Headers();

        headers.append('X-Requested-With', 'XMLHttpRequest');
        headers.append('Accept', 'application/json');

        return headers;
    }

    /**
     * Método responsável por retornar um objeto Headers configurado.
     */
    static getHeadersText() {
        const headers = new Headers();

        headers.append('X-Requested-With', 'XMLHttpRequest');
        headers.append('Accept', 'application/text');

        return headers;
    }

}
