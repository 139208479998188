/* global CertificadoUtil, AssinadorAdapterFactory */

/**
 * Classe responsável por gerenciar as telas do assinador
 *
 * @type {AssinadorForm}
 */
class AssinadorForm {

    /**
     * Método construtor da classe.
     *
     * @return {AssinadorForm}
     */
    constructor() {
        this.listCertificates = new Map();

        this.comboCertificados = document.querySelector('#combo_certificados');
        this.certificadoFimValidade = document.querySelector('#validade_id');
        this.certificadoCnpj = document.querySelector('#certificado_cnpj');
        this.certificadoDataNascimento = document.querySelector('#certificado_data_nascimento');
        this.certificadoEmail = document.querySelector('#certificado_email');
        this.certificadoEmissor = document.querySelector('#certificado_emissor');
        this.numeroSerial = document.querySelector('#certificado_numero_serial');
        this.certificadoTipo = document.querySelector('#certificado_tipo');
        this.contentPdfOriginal = document.querySelector('#content_pdf_original_base64');
        this.btnAssinar = document.querySelector('#assinar');
        this.modoOperacao = document.querySelector('#modoOperacao');
        this.politicaAssinatura = document.querySelector('#politicaAssinatura');
        this.registroAssinar = document.querySelector('#id_registro_assinar');
        this.btnFechar = document.querySelector('.btn-i-nao.right.fechar_msg');
        this.cpfProfissionalLogado = document.querySelector('#cpf_profissional_logado');
        this.parametroSomenteCertificadosProfissional = document.querySelector('#parametro_somente_certificados_profissional');
        this.btnRecarregar = document.querySelector('#atualiza_lista');
        this.loaderAssinar = document.querySelector('#loader_assinar');
    }

    /**
     * Método responsável por popular os campos da tela com os dados do certificado.
     *
     * @param {HTMLElement} itemCertificado
     * @return void
     */
    populateFields(itemCertificado) {
        if (!(itemCertificado !== null && itemCertificado !== undefined)) {
            this.cleanFields();

            return;
        }

        if (this.certificadoFimValidade) {
            this.certificadoFimValidade.value = itemCertificado.expirationDate;
        }

        if (this.certificadoCnpj) {
            this.certificadoCnpj.value = itemCertificado.certificadoCnpj;
        }

        if (this.certificadoDataNascimento) {
            this.certificadoDataNascimento.value = itemCertificado.certificadoDataNascimento;
        }

        if (this.certificadoEmail) {
            this.certificadoEmail.value = itemCertificado.certificadoEmail;
        }

        if (this.certificadoEmissor) {
            this.certificadoEmissor.value = itemCertificado.certificadoEmissor;
        }

        if (this.numeroSerial) {
            this.numeroSerial.value = itemCertificado.numeroSerial;
        }

        if (this.certificadoTipo) {
            this.certificadoTipo.value = itemCertificado.certificadoTipo;
        }
    }

    /**
     * Método responsável por limpar os campos da tela.
     *
     * @return void
     */
    cleanFields() {
        if (this.certificadoFimValidade) {
            this.certificadoFimValidade.value = '';
        }

        if (this.certificadoCnpj) {
            this.certificadoCnpj.value = '';
        }

        if (this.certificadoDataNascimento) {
            this.certificadoDataNascimento.value = '';
        }

        if (this.certificadoEmail) {
            this.certificadoEmail.value = '';
        }

        if (this.certificadoEmissor) {
            this.certificadoEmissor.value = '';
        }

        if (this.numeroSerial) {
            this.numeroSerial.value = '';
        }

        if (this.certificadoTipo) {
            this.certificadoTipo.value = '';
        }
    }

    /**
     * Método de assinatura - deve ser implementado pela classe derivadora.
     *
     * @returns {void}
     */
    assina() {
        throw new Error('O método assina deve ser sobrescrevido.');
    }

    /**
     * Método responsável por validar se foi selecionado um certificado.
     *
     * @returns {void}
     */
    verificaCertificadoSelecionado() {
        if (!this.comboCertificados.value) {
            throw new Error('Selecione um certificado.');
        }
    }

    /**
     * Método responsável por habilitar/desabilita o botão de assinar.
     *
     * @param {type} desabilita
     * @returns {void}
     */
    desabilitaBtn(desabilita) {
        if (!desabilita) {
            this.btnAssinar.disabled = false;
            this.btnAssinar.classList.remove('btn-loading');
        } else {
            this.btnAssinar.disabled = true;
            this.btnAssinar.classList.add('btn-loading');
        }
    }

    showLoading() {
        this.loaderAssinar.classList.remove('hide');
    }

    hideLoading() {
        this.loaderAssinar.classList.add('hide');
    }

    /**
     * Método responsável por criar o objeto que será enviado para assinatura.
     *
     * @returns {AssinaturaPdf}
     */
    factoryAssinaturaPdf() {
        return new AssinaturaPdf(
            this.comboCertificados.value,
            this.contentPdfOriginal.value,
            this.modoOperacao.value,
            '',
            this.politicaAssinatura.value,
            this.registroAssinar.value
        );
    }
}