class Digitalsignature {
    static async showFileDownload(id) {
        Utils.startWait();

        try {
            const response = await fetch(`/padrao/digitalsignature/showfiledownload/id/${id}`, {
                method: 'GET',
                headers: Utils.getHeadersText(),
                credentials: 'same-origin'
            });

            const data = await response.text();

            Utils.stopWait();

            Utils.openBlockUI(data, 'Visualizar arquivo');
        } catch (e) {
            // @ts-ignore
            alert(trata_erro2(e, '', ''));
        }
    }

    static async verify(id) {
        try {
            Utils.startWait();

            // @ts-ignore
            let data = await (new AssinadorAdapter()).validPdf(id, false);

            Utils.stopWait();

            Utils.openBlockUI(data, 'Verificar');
        } catch (e) {
            // @ts-ignore
            alert(trata_erro2(e, '', ''));
        }
    }

    static async verifyAlert(id) {
        try {
            $('#result_verify').removeClass('hide');

            // @ts-ignore
            const assinadoAdapter = new AssinadorAdapter();

            const resultValidSignature = await assinadoAdapter.validPdf(id, false);

            if (typeof resultValidSignature === 'object') {
                $('#result_verify').addClass('hide');
                alert(resultValidSignature.error);
            } else {
                $('#result_verify').html(resultValidSignature);
            }
        } catch (e) {
            // @ts-ignore
            alert(trata_erro2(e, '', ''));
            $('#result_verify').addClass('hide');
        }
    }
}

$(document).ready(() => new Digitalsignature());
